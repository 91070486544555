@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  margin: unset;
}

html {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.app_wrapper {
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
}

.border-reds {
  border-color: red;
}

@media screen and (max-width:450px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.hidescroll::-webkit-scrollbar {
  display: none;
}




::-webkit-scrollbar {
  width: 9px;
  height: 18px;

}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(244, 245, 234, 0.9);
}

.scroll::-webkit-scrollbar {
  width: 6px;
  height: 18px;

}

.top_heading {

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 42px;
  display: flex;
  margin: unset;

  @media screen and (max-width: 900px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-right: 2px;
  }
}

.inter {
  font-family: "Inter", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.Rubik {
  font-family: "Rubik", sans-serif;
}

input[type="checkbox"] {
  transform: scale(1.5);
  margin: 0px 30px 10px;
}

.tick {
  accent-color: #005555;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width:450px) {
  .mob-none {
    display: none;
  }

  .ld-widget {
    width: 200px;
    height: 280px;
  }
}

#animate {
  @apply animate-spin
}

.name {
  font-family: DynaPuff;
}

.other-name {
  font-family: Pacifico;
}

.trend {
  font-family: Kanit;
}

.special {
  font-family: Dancing Script;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  display: none;
}

body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
  position: relative;
}

#bold {
  @apply font-semibold
}

#not-bold {
  @apply font-normal
}

.collection {
  font-family: Montserrat;
}

/* The container */
.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The container radio */
.contain-radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  padding-top: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contain-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.circle {
  @apply absolute top-0 left-0 h-6 w-6 rounded-full border-zinc-800 border-2 bg-white;
}

.contain-radio .circle:after {
  left: 4px;
  top: 4px;
  @apply w-3 h-3 rounded-full bg-zinc-800;

}

.contain-radio input:checked~.circle:after {
  display: block;
}

.circle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 1px solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.meals::-webkit-scrollbar {
  display: none;

}

#show-cat {
  @apply rounded-b-none
}

#show-flex {
  display: flex
}

#show {
  display: block;
}

#hide {
  display: none;
}



.let {

  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeIn {
  animation-name: swipeIn;
  -webkit-animation-name: swipeIn;
}

@keyframes swipeIn {
  0% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0px);
  }
}


@-webkit-keyframes swipeIn {
  0% {
    -webkit-transform: translateX(200%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.swipeInLeft {
  animation-name: swipeInLeft;
  -webkit-animation-name: swipeInLeft;

}

@keyframes swipeInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeInLeft {
  0% {
    -webkit-transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.lets {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeDown {
  animation-name: swipeUp;
  -webkit-animation-name: swipeUp;
}

@keyframes swipeUp {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeUp {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

#show-border {
  @apply text-[#7BBE4A] border-[#7BBE4A] bg-[#F7F7F7] border-b-2
}

#hide-food {
  display: none;
}



@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";