.input-field {
    width:100%;
     border:1px solid black;
     background:white;
       
  }
  
  label.label input[type="file"] {
    position: absolute;
    top: -1000px;
  }
  .label {
    cursor: pointer;
    margin-left: 3px;
    display: inline-block;
  }
  /* The container */
  .container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: white;
  }
  
  /* When the checkbox is checked, add a blue background */
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
   background:black;
}

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

  