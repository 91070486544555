
.ld_widget {
    @media screen and (min-width:450px) {
        width:100%;
        height:fit-content;
    }

    .img_sz {
        @media screen and (min-width:450px) {
            width:100%;
            height:220px;
        }
    }
}