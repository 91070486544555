

.overflow_hidden_wrapper,
.overflow_hidden_wrapper_lect,
.overflow_hidden_wrapper_talb {
  width:100%;
  overflow-x:hidden;
  position:relative;

  .prevs,
  .nexts {
    cursor: pointer;
    position: absolute;
   // top: 30px;
    //width: auto;
    padding: 1px;
    color: white;
    font-weight: bold;
    bottom:90px;
    @media screen and (max-width:450px) {
        bottom:75px;
        width:20px;
    }
    z-index:40;
    //padding:3px 12px;
    background:rgba(0,0,0,0.6);
    //font-size: 18px;
    transition: 0.7s ease;
    border-radius: 50%;
    text-decoration: none;
    user-select: none;
    
  }
  .nexts {
    right: 0px;
    @media screen and (max-width:450px) {
        right:0px;
    }
  
  }
  .prevs {
    left: 0px;
    @media screen and (max-width:450px) {
        left:0px;
    }
 
  }
  
.prev_none {
display:none;
}
.next_none {
display:none;
}
}
.overflow_auto_wrapper::-webkit-scrollbar,
.overflow_auto_wrapper_lect::-webkit-scrollbar,
.overflow_auto_wrapper_talb::-webkit-scrollbar{
  display:none;
};
.overflow_auto_wrapper {
  @media screen and (max-width:600px) {
    gap:0.5em;
    padding-right:2em;
    padding-left:4px;
  }
}
.overflow_auto_wrapper,
.overflow_auto_wrapper_lect,
.overflow_auto_wrapper_talb {
  width:105%;
  display:flex;
  gap:1em;
  padding-right:4em;
  padding-left:1em;
  
  flex-direction: row;
  overflow: auto;

  

 
    
  
  
}