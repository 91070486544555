
.sc_widget {
    @media screen and (min-width:450px) {
        width:190px;
        height:fit-content;
    } 

    .img_sz {
        @media screen and (min-width:450px) {
           
            height:195px;
        } 
       
    }
}