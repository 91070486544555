/*group wdget*/
@mixin hide_for_mobile {
    @media screen and (max-width:615px) {
      display:none;
    }
  }

.groupWidget_wrapper {
    display: flex;
    margin-top:1rem;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    .groupWidget_top {
      padding: 0rem 2%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;
      @media screen and (max-width: 600px) {
        padding: 0rem 2%;
        
        width: 100%;
      }
      .groupWidget_top_heading {
        
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 42px;
        display: flex;
        margin: unset;
        @media screen and (max-width: 900px) {
          font-size: 20px;
      }
        @media screen and (max-width: 600px) {
            font-size: 16px;
            margin-right:2px;
        }
      }
      .groupWidget_more {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .groupWidget_more_text {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color:black;
          margin: unset;
        }
        .groupWidget_more_icon {
            color:black;
          font-size: 20px;
          margin-left: 0.2rem;
        }
      }
    }
    .overflow_hidden_wrapper,
    .overflow_hidden_wrapper_lect,
    .overflow_hidden_wrapper_talb {
      width:100%;
      overflow-x:hidden;
      position:relative;
  
      .prevs,
      .nexts {
        cursor: pointer;
        position: absolute;
       // top: 30px;
        //width: auto;
        padding: 1px;
        color: white;
        font-weight: bold;
        bottom:95px;
        @media screen and (max-width:450px) {
          bottom:75px;
          width:20px;
      }
        z-index:40;
        //padding:3px 12px;
        background:rgba(0,0,0,0.6);
        //font-size: 18px;
        transition: 0.7s ease;
       // border-radius: 50%;
        text-decoration: none;
        user-select: none;
        
      }
      .nexts {
        right: 7px;
        @media screen and (max-width:450px) {
          right:0px;
      }
      }
      .prevs {
        left: 15px;
        @media screen and (max-width:450px) {
          left:0px;
      }
      }
      
  .prev_none {
    display:none;
  }
  .next_none {
    display:none;
  }
    }
    .overflow_auto_wrapper::-webkit-scrollbar,
    .overflow_auto_wrapper_lect::-webkit-scrollbar,
    .overflow_auto_wrapper_talb::-webkit-scrollbar{
      display:none;
    };
    .overflow_auto_wrapper {
      @media screen and (max-width:600px) {
        gap:0.5em;
        padding-right:2em;
        padding-left:4px;
      }
    }
    .overflow_auto_wrapper,
    .overflow_auto_wrapper_lect,
    .overflow_auto_wrapper_talb {
      width:100%;
      display:flex;
      gap:1em;
      padding-right:4em;
      padding-left:1em;
      
      flex-direction: row;
      overflow: auto;
  
      
        .groupWidget_album_item {
          cursor: pointer;
        }
        .groupWidget_album_item {
          width: fit-content !important;
          height: fit-content !important;
        }
        .groupWidget_album_item_talb {
          @include hide_for_mobile;
        }
        
      
      
    }
  }